<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3">
        <div class="col-md-6">
          <h2>Senarai Fail</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <button
            class="btn btn-outline-secondary w-50"
            data-bs-toggle="modal"
            data-bs-target="#addFileModal"
          >
            Tambah Fail +
          </button>
        </div>
      </div>
    </div>
    <div class="card-lists">
      <div class="row mx-0">
        <div class="col col-md-3 col-sm-6" v-for="file in files" :key="file.id">
          <div class="card mb-4" @click="copy(file)">
            <div class="card-body position-relative">
              <span
                class="position-abolute top-0 float-end"
                @click="copy(file)"
              >
                <i class="far fa-copy"></i>
              </span>
              <div
                class="position-absolute bottom-0 mb-2"
                style="overflow: hidden"
              >
                <h5 class="card-title" style="height: 24px; overflow: hidden">
                  {{ file.name }}
                </h5>
                <p>{{ formatDate(file.createdAt) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- -->
    <div
      class="modal"
      id="addFileModal"
      tabindex="-1"
      aria-labelledby="addFileModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h5>Tambah Fail</h5>
            <div class="row mx-0">
              <div class="col-12 pt-2">
                <h6 class="mt-2">Fail</h6>
                <div class="file-upload-box">
                  <div class="file-upload-box-content" v-if="!fileRef">
                    <input
                      accept="image/png,image/jpeg,application/pdf/video/*/audio/mp3,audio/*"
                      type="file"
                      id="relatedFile"
                      lang="es"
                      @change="fileUploaded"
                      ref="relatedFile"
                      hidden
                    />
                    <label for="relatedFile" class="title d-block"
                      >Klik untuk memuat naik</label
                    >
                    <span class="d-block">PDF, JPG atau PNG (max 10MB)</span>
                    <div
                      class="spinner-border mt-2"
                      role="status"
                      v-if="isLoading"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <!-- <button
              type="button"
              class="btn btn-primary"
              @click="editMedia"
            >
              Simpan
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import API from "../../utils/API";
import moment from "moment";
export default {
  data() {
    return {
      files: null,
      fileRef: null,
      isLoading: false,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD MMMM YYYYY");
    },
    async copy(file) {
      navigator.clipboard.writeText(file.url);
      const Toast = Swal.mixin({
        toast: false,
        // position: "top-right",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: true,
        // timer: 1500,
        // timerProgressBar: true,
      });
      await Toast.fire({
        icon: "success",
        title: "Copy to clipboard",
      });
    },
    async fileUploaded() {
      if (this.$refs.relatedFile && this.$refs.relatedFile.files[0]) {
        this.isLoading = true;
        let file = this.$refs.relatedFile.files[0];
        console.log(this.$refs.relatedFile.files[0]);
        let formData = new FormData();
        formData.append("files", file);
        await API.post("upload", formData)
          .then((res) => {
            this.isLoading = false;
            console.log(res.data[0]);
            this.files.unshift(res.data[0]);
            $("#addFileModal").toggle();
            $(".modal-backdrop").remove();
          })
          .catch((e) => {
            this.isLoading = false;
            console.log(e);
          });
      }
    },
  },
  async mounted() {
    await API.get(`upload/files?sort[0]=createdAt%3Adesc`).then((res) => {
      console.log(res);
      this.files = res.data;
    });
  },
};
</script>

<style scoped>
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}
</style>